.notifications {
  direction: ltr;
  margin-bottom: 20px;

  .notifications-box {
    display: flex;
    justify-content: flex-end;

    label {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      span {
        padding-right: 24px;
        padding-left: 45px;
      }
    }
  }
}
