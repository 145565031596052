@import '../../../styles/abstract/palette';

.input-wrapper {
    height: 60px;

    .email {
        .icon {
            height: 14px;
        }
    }

    .password {
        .icon {
            height: 20px;
        }
    }

    .searchQuery {
        .icon {
            height: 17px;
        }
    }

    .input {
        position: relative;

        img {
            position: absolute;
            right: 0;
            padding: 0 10px;
        }

        input[name='searchQuery'] {
            width: 300px;
            color: $color-dusty-orange;

            @media (max-width: 1440px) {
                width: 250px;
            }

            &::placeholder {
                color: $color-dusty-orange;
            }
        }

        .icon {
            top: 50%;
            transform: translateY(-50%);
        }

        .invalid-tooltip {
            z-index: 10;
            left: 0;
        }

        input {
            background-color: $color-white;
            border-radius: 4px;
            font-size: 22px;
            width: 445px;
            height: 42px;
            color: $color-dark-blue-grey;
            border: 1px solid $color-very-light-blue;
            padding: 0 40px 2px 0;

            &:focus {
                box-shadow: none;
                border-color: $color-bright-light-blue;
            }
        }

        .invalid-tooltip {
            background-color: $color-white;
            color: rgba(220, 53, 69, 0.9);
            font-weight: bold;
            border: 1px solid rgba(220, 53, 69, 0.9);
        }
    }
}
