@import "../../../../styles/abstract/palette";

.speakers {
    margin-top: 10px;
  padding-bottom: 10px;

  .speaker-header {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    font-weight: bold;
    font-size: 14px;
  }

  .configure-button {
    position: inherit;
  }

  table {
    width: 100%;

    th {
      font-weight: normal;
    }

    tr {
      border-bottom: 1px solid rgba($color-dark-blue-grey, 0.2);
      padding: 5px 0;
    }

    td {
      padding: 2px 0;
    }

    .speaker-name {
      color: $color-bright-light-blue;
    }

    .shortcut {
      background-color: $color-dark-blue-grey;
      color: $color-white;
      border-radius: 10px;
      font-weight: bold;
      padding: 2px 10px;
      font-size: 12px;
    }
  }
}
