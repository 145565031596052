.download-link {
  p {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-top: 20px;
    a {
      font-weight: bold;
      padding-right: 2px;
    }
  }

  .submit-button {
    text-align: center;
    margin-bottom: 17px;
    margin-top: 30px;
    button {
      font-size: 21px;
      padding: 0.35rem 70px;
    }
  }
}
