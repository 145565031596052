@import '../../../../styles/abstract/palette';

.speakers-modal {
    padding: 25px;
    max-height: 90vh;

    .modal-header {
        border: none;

        .modal-title {
            font-size: 35px;
        }
    }

    .modal-body {
        overflow: auto;
        table {
            width: 100%;

            thead {
                tr {
                    background-color: $color-very-light-blue;
                    border-radius: 3px;
                    border: none;
                }

                th {
                    font-size: 20px;
                    font-weight: normal;

                    &:last-child {
                        width: 185px;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $color-very-light-blue;
                    &:first-child {
                        td {
                            padding-top: 15px;
                        }
                    }
                    td {
                        padding: 7px 0;
                    }
                }

                .delete-speaker {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                }

                input {
                    height: 28px;
                    width: 100%;
                    color: $color-bright-light-blue;
                    border: 1px solid $color-ghost;
                    font-size: 1rem;
                    padding-right: 10px;

                    &:focus {
                        color: $color-bright-light-blue;
                    }
                }
                .input-wrapper {
                    height: 28px;
                }
                .speaker-input {
                    width: 160px;
                }

                .shortcut-input {
                    width: 160px;
                }
            }
        }

        .add-speaker {
            margin-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
            margin-right: 10px;
            z-index: 1;

            .text {
                padding-right: 20px;
                font-size: 18px;
                line-height: 2;
            }
        }
    }
}
