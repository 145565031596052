@import '../../../../styles/abstract/palette';
.project-header-info {
    display: flex;
    align-items: center;
    .category-info {
        margin-right: 7px;
        display: flex;
        .info {
            padding-left: 50px;
            .value {
                color: $color-mid-blue;
            }
        }
    }
    .submit-button {
        button {
            font-size: 21px;
            padding: 0.375rem 32px;
        }
    }
    .export-button {
        margin-right: auto;
        margin-left: 10px;
    }
}
