// use http://chir.ag/projects/name-that-color/

$color-black: #000000;
$color-white: #ffffff;
$color-tundora: #4a4a4a;
$color-very-light-blue: #f6f6f6;
$color-dusty-orange: #f37341;
$color-bright-light-blue: #35c4e8;
$color-dark-blue-grey: #233e4f;
$color-steel: #7c8487;
$color-light-periwinkle: #dee0e3;
$color-mid-blue: #257aa9;
$color-pale-lilac: #ececed;
$color-cloudy-blue: #a9bbe1;
$color-very-light-pink: #dddddd;
$color-ice-blue: #e2f7fc;
$color-very-light-pink-two: #fdebe4;
$color-iron: #E6E7E8;
$color-pale-grey: #f3f3f4;
$color-cool-blue: #4a90b7;
$color-light-grey: #aaaaaa;
$color-athens-gray: #F4F3F5;
$color-bluish: #2c98d2;
$color-bright-yellow: #fff600;
$color-cool-grey: #949c9f;
$color-pickled-bluewood: #2F4554;
$color-steel-two: #8b8c92;
$color-cloudy-blue-two: #bbc0c2;
$color-very-light-pink-three: #b7b7b7;
$color-ghost: #ced4da;
$color-green: #0ab116;
$color-red: #bb0000;
$color-pinkish-grey: #cccccc;
$color-iron: #EAEAEB;
