@import '../../../../styles/abstract/palette';

.notification-icon {
    background-color: $color-steel;
    color: $color-white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    cursor: pointer;

    .has-notification-dot {
        width: 7px;
        height: 7px;
        background-color: $color-steel;
        display: inline-block;
        border-radius: 100%;
        position: absolute;
        bottom: -11px;
    }
}

.has-notification-text {
  color: $color-bright-yellow
}

.notification-popover {
    min-width: 300px;
    .no-notifications {
        font-size: 15px;
        text-align: center;
        padding: 10px 0;
    }
    .unread {
        background-color: $color-bright-yellow;
    }
    .popover-body {
        padding: 0;

        ul {
            padding: 10px 0 0 0;
            margin: 0;
            .unread-bg {
                background-color: #e2f7fc;
            }
            li {
                margin: 0;
                list-style: none;
                padding: 0.5rem 20px 0.5rem 0;
                &:last-child {
                    border-bottom: none;
                }

                .notification-info {
                    color: $color-dark-blue-grey;
                    font-size: 14px;

                    p {
                        padding-left: 40px;
                        margin-right: 15px;
                    }

                    .notification-title {
                        position: relative;
                        font-weight: bold;
                        margin-right: 15px;

                        .lightbulb-icon {
                            height: 25px;
                            position: absolute;
                            right: -5px;
                            top: 65%;
                            transform: translateY(-50%);
                            border-radius: 100%;
                        }
                    }
                }

                &:last-child {
                    .notification-info {
                        border-bottom: none;
                    }
                }
            }
        }

        .all-notifications-link {
            border-top: 1px solid rgba($color-cloudy-blue, 0.4);
            background-color: $color-very-light-blue;
            text-align: center;
            padding: 8px 0;

            a {
                color: $color-dusty-orange;
                font-weight: bold;
                text-decoration: underline;
                font-size: 15px;
            }
        }
    }
}
