.entry {
    width: 100%;
    padding: 0;

    .paragraph {
        padding: 5px 20px 5px 35px;

        p {
            margin: 0;
        }
        display: flex;
        & + .paragraph {
            border-top: 1px dotted #cccccc;
        }

        .speaker {
            width: 150px;
            font-weight: 600;
            margin-left: 20px;
            padding-left: 10px;
            border-left: 1px dashed #cccccc;

            .dropdown {
                margin: 0;
                height: auto;
                button {
                    min-width: 215px;
                }
            }
        }
    }
}
