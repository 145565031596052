.search-page {
    .bg {
        width: 100%;
        min-height: 248px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../../assets/images/search-page-no-results-strip.png');
		//text-align: center;
		position: relative;
    }

	.illustration {
		max-width: 1024px;
		position: relative;
		top: -25px;
	}
}
