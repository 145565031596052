@import '../../../styles/abstract/palette';

.header {
  background-color: $color-white;
  padding: 10px 0;
  .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-right {
      display: flex;
      align-items: center;
      .logo {
        height: 50px;
      }
      .link-nav {
        margin-right: 60px;
        display: flex;
        align-items: center;
        a {
          color: $color-dusty-orange;
          font-size: 21px;
        }
        .orange-bar {
          color: $color-dusty-orange;
          font-size: 21px;
          padding: 0 10px;
        }
        .submit-button {
          padding-right: 25px;
          button {
            font-size: 21px;
          }
        }
      }
      .dropdown {
        margin-right: 25px;
      }
    }
  }

  .header-icons {
    display: flex;
    align-items: center;
    width: 220px;
    .full-name {
      font-size: 16px;
      color: $color-steel;
    }
    a {
      font-size: 16px;
      color: $color-steel;
      text-decoration: underline;
    }
  }
  .header-dropdown-toggle {
    color: $color-dusty-orange;
    border: 1px solid rgba(0, 0, 0, 0.11);
    text-align: center;
    box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11);
    padding: 0.375rem 1.5rem;
    background-color: $color-white;
    font-size: 21px;

    &:focus {
      box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11);
    }
  }
}
