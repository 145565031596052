@import "../../../../styles/abstract/palette";

.profile-icon {
  .profile-image {
    cursor: pointer;

    padding: 0 15px;

    img {
      width: 35px;
      height: 35px;
      border-radius: 100%;
    }
  }
}

.profile-popover {
  .popover-body {
    padding: 0;
    width: 160px;
    ul {
      margin: 0;
      padding-right: 5px;
      list-style: none;

      li {
        padding: .5rem .75rem;

        a {
          color: $color-dark-blue-grey;
          font-size: 14px;
        }

        border-bottom: 1px solid rgba($color-cloudy-blue, 0.4);


        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

