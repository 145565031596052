@import '../../../styles/abstract/palette';
.tab {
    .add {
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 25px;

        .text {
            padding-right: 20px;
            font-size: 18px;
            line-height: 2;
        }
    }
    .input-wrapper {
        height: auto;
        width: 445px;
        margin-top: 20px;

        .input {
            input {
                border: 1px solid $color-ghost;
            }
            label {
                font-size: 18px;
                color: rgba($color-dark-blue-grey, 0.6);
            }
        }
    }
    .table {
        thead {
            tr {
                background-color: transparent;
                border: none;
                th {
                    text-align: right;
                    font-size: 18px;
                    color: rgba($color-dark-blue-grey, 0.6);
                    font-weight: normal;
                }
            }
        }
        tbody {
            tr {
                td {
                    text-align: right;
                    padding: 7px;
                    &:nth-child(1) {
                        width: 20px;
                    }
                    &:nth-child(2) {
                        width: 400px;
                    }
                }
            }
        }
    }
}
