@import '../../../../styles/abstract/palette';
.export-bar {
    height: 46px;
    background-color: $color-pickled-bluewood;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    & > li {
        display: inline-block;
        zoom: 1;
        color: white;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: all 200ms;
        &:hover {
            color: $color-bright-light-blue;
        }
    }
    img {
        margin-left: 13px;
    }
    .quotes img {
        height: 15px;
    }
    .export img {
        height: 21px;
    }
    .bubble img {
        height: 21px;
    }
    .message img {
        height: 18px;
    }
    .line-break {
        padding-right: 23px;
        padding-left: 23px;
        color: $color-bright-light-blue;
    }
}
