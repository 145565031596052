@import '../../../styles/abstract/palette';

.header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .right-side{
    display: flex;
    align-items: center;
  }
  .dropdown {
    margin-left: 20px;
    button {
      border: none;
      background-color: $color-white;
      display: flex;
      align-items: baseline;
      width: 240px;
      &:hover {
        background-color: $color-white;
        color: $color-dark-blue-grey;
      }
      &::after {
        margin-right: 0.255em;
      }
    }
    .dropdown-menu {
      width: 100%;
      a {
        color: $color-mid-blue;
      }
    }
  }
  .file-info {
    font-size: 16px;
    .item {
      padding: 0 10px;
    }
    .value {
      color: $color-mid-blue;
    }
  }
  .submit-buttons {
    display: flex;
    border:0;
    .submit-button {
      margin: 0 14px;
      button {
        font-size: 21px;
      }
      .secondary {
        color: $color-mid-blue;
      }
    }
  }
}
