@import '../../../styles/abstract/palette';

.profile-page {
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .profile-box {
    background-color: $color-white;
    border: 1px solid $color-very-light-pink;
    padding-right: 36px;
    padding-top: 42px;
  }
  .personal-info{
    h2{
      margin-top: calc(49px - 27px);
    }

  }
  .personal-details-wrapper,
  .price-methods-wrapper {
    min-height: 370px;
  }
  .price-methods-wrapper {
    padding-top: 42px;
  }
  .custom-offset-7 {
    margin-right: 58.333333%;
  }
  .notifications-wrapper {
    margin-top: 40px;
  }
}
