@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
.full-screen-loading-spinner {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1; }

.popover {
  font-family: 'Open Sans Hebrew', sans-serif; }

.loading-spinner svg {
  color: #f37341; }

.input-wrapper {
  height: 60px; }
  .input-wrapper .email .icon {
    height: 14px; }
  .input-wrapper .password .icon {
    height: 20px; }
  .input-wrapper .searchQuery .icon {
    height: 17px; }
  .input-wrapper .input {
    position: relative; }
    .input-wrapper .input img {
      position: absolute;
      right: 0;
      padding: 0 10px; }
    .input-wrapper .input input[name='searchQuery'] {
      width: 300px;
      color: #f37341; }
      @media (max-width: 1440px) {
        .input-wrapper .input input[name='searchQuery'] {
          width: 250px; } }
      .input-wrapper .input input[name='searchQuery']::-webkit-input-placeholder {
        color: #f37341; }
      .input-wrapper .input input[name='searchQuery']::placeholder {
        color: #f37341; }
    .input-wrapper .input .icon {
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .input-wrapper .input .invalid-tooltip {
      z-index: 10;
      left: 0; }
    .input-wrapper .input input {
      background-color: #ffffff;
      border-radius: 4px;
      font-size: 22px;
      width: 445px;
      height: 42px;
      color: #233e4f;
      border: 1px solid #f6f6f6;
      padding: 0 40px 2px 0; }
      .input-wrapper .input input:focus {
        box-shadow: none;
        border-color: #35c4e8; }
    .input-wrapper .input .invalid-tooltip {
      background-color: #ffffff;
      color: rgba(220, 53, 69, 0.9);
      font-weight: bold;
      border: 1px solid rgba(220, 53, 69, 0.9); }

.popover {
  font-family: 'Open Sans Hebrew', sans-serif; }

.submit-button {
  background-color: #ffffff;
  position: relative;
  display: inline-block;
  margin-bottom: 17px; }
  .submit-button .secondary {
    color: #35c4e8; }
  .submit-button button {
    font-size: 21px;
    min-width: 193px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #f37341;
    border: 1px solid rgba(0, 0, 0, 0.11);
    text-align: center;
    box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11);
    padding: 0.375rem 1.5rem; }
    .submit-button button:focus {
      box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11); }
  .submit-button.auto-width button {
    min-width: inherit; }
  .submit-button:hover button {
    background-color: #f37341;
    color: #ffffff; }
  .submit-button:hover svg {
    color: #ffffff; }

.login-page {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .login-page p {
    font-size: 20px;
    padding: 50px 50px 20px 50px;
    text-align: center; }
  .login-page form {
    width: 620px;
    background-color: #ffffff;
    box-shadow: 28.8px 32px 98px 0 rgba(0, 0, 0, 0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .login-page form .hever-logo {
      margin-top: calc(-120px/ 3); }
      .login-page form .hever-logo img {
        height: 120px; }
    .login-page form .submit-button {
      margin: 20px 0 43px 0; }
      .login-page form .submit-button button {
        padding: 0.375rem 2rem;
        font-size: 20px; }
  .login-page .otp-form {
    display: -webkit-flex;
    display: flex; }
    .login-page .otp-form .submit-button {
      padding: 0 calc(25px / 2); }
      .login-page .otp-form .submit-button button {
        padding: 0 35px;
        line-height: 2.5rem; }
  .login-page .logout {
    color: #f37341;
    cursor: pointer;
    font-size: 20px; }

.tooltip-fix {
  z-index: 10000; }

.header {
  background-color: #ffffff;
  padding: 10px 0; }
  .header .container-fluid {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .header .container-fluid .header-right {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .header .container-fluid .header-right .logo {
        height: 50px; }
      .header .container-fluid .header-right .link-nav {
        margin-right: 60px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .header .container-fluid .header-right .link-nav a {
          color: #f37341;
          font-size: 21px; }
        .header .container-fluid .header-right .link-nav .orange-bar {
          color: #f37341;
          font-size: 21px;
          padding: 0 10px; }
        .header .container-fluid .header-right .link-nav .submit-button {
          padding-right: 25px; }
          .header .container-fluid .header-right .link-nav .submit-button button {
            font-size: 21px; }
      .header .container-fluid .header-right .dropdown {
        margin-right: 25px; }
  .header .header-icons {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 220px; }
    .header .header-icons .full-name {
      font-size: 16px;
      color: #7c8487; }
    .header .header-icons a {
      font-size: 16px;
      color: #7c8487;
      text-decoration: underline; }
  .header .header-dropdown-toggle {
    color: #f37341;
    border: 1px solid rgba(0, 0, 0, 0.11);
    text-align: center;
    box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11);
    padding: 0.375rem 1.5rem;
    background-color: #ffffff;
    font-size: 21px; }
    .header .header-dropdown-toggle:focus {
      box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11); }

.notification-icon {
  background-color: #7c8487;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  cursor: pointer; }
  .notification-icon .has-notification-dot {
    width: 7px;
    height: 7px;
    background-color: #7c8487;
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    bottom: -11px; }

.has-notification-text {
  color: #fff600; }

.notification-popover {
  min-width: 300px; }
  .notification-popover .no-notifications {
    font-size: 15px;
    text-align: center;
    padding: 10px 0; }
  .notification-popover .unread {
    background-color: #fff600; }
  .notification-popover .popover-body {
    padding: 0; }
    .notification-popover .popover-body ul {
      padding: 10px 0 0 0;
      margin: 0; }
      .notification-popover .popover-body ul .unread-bg {
        background-color: #e2f7fc; }
      .notification-popover .popover-body ul li {
        margin: 0;
        list-style: none;
        padding: 0.5rem 20px 0.5rem 0; }
        .notification-popover .popover-body ul li:last-child {
          border-bottom: none; }
        .notification-popover .popover-body ul li .notification-info {
          color: #233e4f;
          font-size: 14px; }
          .notification-popover .popover-body ul li .notification-info p {
            padding-left: 40px;
            margin-right: 15px; }
          .notification-popover .popover-body ul li .notification-info .notification-title {
            position: relative;
            font-weight: bold;
            margin-right: 15px; }
            .notification-popover .popover-body ul li .notification-info .notification-title .lightbulb-icon {
              height: 25px;
              position: absolute;
              right: -5px;
              top: 65%;
              -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
              border-radius: 100%; }
        .notification-popover .popover-body ul li:last-child .notification-info {
          border-bottom: none; }
    .notification-popover .popover-body .all-notifications-link {
      border-top: 1px solid rgba(169, 187, 225, 0.4);
      background-color: #f6f6f6;
      text-align: center;
      padding: 8px 0; }
      .notification-popover .popover-body .all-notifications-link a {
        color: #f37341;
        font-weight: bold;
        text-decoration: underline;
        font-size: 15px; }

.profile-icon .profile-image {
  cursor: pointer;
  padding: 0 15px; }
  .profile-icon .profile-image img {
    width: 35px;
    height: 35px;
    border-radius: 100%; }

.profile-popover .popover-body {
  padding: 0;
  width: 160px; }
  .profile-popover .popover-body ul {
    margin: 0;
    padding-right: 5px;
    list-style: none; }
    .profile-popover .popover-body ul li {
      padding: .5rem .75rem;
      border-bottom: 1px solid rgba(169, 187, 225, 0.4); }
      .profile-popover .popover-body ul li a {
        color: #233e4f;
        font-size: 14px; }
      .profile-popover .popover-body ul li:last-child {
        border-bottom: none; }

.common-table {
  font-size: 14px;
  margin-bottom: 45px; }
  .common-table thead tr {
    border: 1px solid #dddddd;
    background-color: #F4F3F5; }
  .common-table thead .file-name-column {
    width: 25%; }
  .common-table thead .speakers-column {
    width: 250px; }
  .common-table thead .actions-column {
    width: 100px; }
  .common-table thead .project-column {
    min-width: 250px; }
  .common-table thead .duedate-column {
    min-width: 200px; }
  .common-table thead th {
    text-align: center; }
  .common-table tbody tr {
    background-color: #ffffff; }
    .common-table tbody tr td {
      border-bottom: 1px solid #EAEAEB;
      text-align: center; }

.pagination-table {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .pagination-table .pagination-item {
    background-color: #f9f9f9 !important;
    color: #f37341; }

.common-table tbody .under-work {
  background-color: #e2f7fc;
  color: #35c4e8; }

.common-table tbody .almost-due {
  background-color: #fdebe4;
  color: #f37341; }

.common-table tbody .encoding {
  background-color: #f3f3f4;
  color: #f37341; }

.main-page h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 25px; }

.main-page .action-button {
  cursor: pointer;
  text-align: center;
  width: 100px; }

.search-title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .search-title input {
    margin-left: 25px; }
  .search-title .input-wrapper {
    height: auto; }

.footer {
  height: 50px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .footer .border {
    border: 1px solid #cccccc; }
  .footer .social {
    width: 140px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center; }
    .footer .social img {
      width: 17px;
      height: 100%; }
  .footer .hever-details {
    width: 70%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 30px; }
    .footer .hever-details a {
      color: #2F4554;
      text-decoration: none; }
  .footer .copyright {
    width: 30%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    color: #f37341; }

.history-page .total {
  text-align: left;
  color: #4a90b7;
  font-size: 20px;
  font-weight: bold; }

.history-page .table-title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 15px; }

.history-page h2 {
  font-size: 21px;
  font-weight: bold; }

.history-page table thead tr th {
  border-bottom: none;
  text-align: center; }
  .history-page table thead tr th:first-child {
    width: 40%;
    text-align: right; }

.history-page table tbody tr:first-child td {
  border-top: none; }

.history-page table tbody tr td {
  text-align: center; }
  .history-page table tbody tr td:first-child {
    text-align: right; }

.dropdown {
  height: 42px;
  margin: 10px; }
  .dropdown .dropdown-item {
    background-color: #ffffff;
    border: none;
    border-bottom: 1px solid rgba(234, 234, 235, 0.7); }
    .dropdown .dropdown-item:last-child {
      border-bottom: none; }
  .dropdown button {
    background-color: #f3f3f4;
    color: #233e4f;
    width: 215px;
    border: 1px solid #EAEAEB;
    text-align: right;
    height: 42px; }
    .dropdown button::after {
      position: absolute;
      top: 50%;
      left: 10px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.search-history .dropdowns {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px; }
  .search-history .dropdowns .dropdown {
    margin: 0 16px; }
    .search-history .dropdowns .dropdown:first-child {
      margin: 0 19px 0 10px; }
  .search-history .dropdowns .submit-button {
    margin-right: calc(24px - 19px); }
    .search-history .dropdowns .submit-button button {
      font-size: 22px;
      line-height: 27px;
      padding-right: 37px;
      padding-left: 37px; }

.title {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin: 1.5rem 0; }
  .title h1 {
    font-size: 36px;
    color: #7c8487;
    padding: 10px 20px; }

.notification-item {
  background-color: #ffffff;
  font-size: 16px;
  border-bottom: 1px solid #EAEAEB; }
  .notification-item.unread-bg {
    background-color: #e2f7fc; }
  .notification-item .notification-item-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #EAEAEB; }
    .notification-item .notification-item-wrapper img {
      width: 35px;
      height: 44px;
      margin: 25px; }
  .notification-item .notification-creation-date {
    color: #f37341;
    display: -webkit-flex;
    display: flex;
    padding: 10px calc(35px + 25px * 2) 10px 0; }
    .notification-item .notification-creation-date .middle-created-date-item {
      padding: 0 50px; }

.notification-page ul {
  list-style: none;
  padding: 0 20%; }

.notification-page li {
  margin: 16px 0; }
  .notification-page li a {
    color: #233e4f;
    text-decoration: none; }

.notifications {
  direction: ltr;
  margin-bottom: 20px; }
  .notifications .notifications-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .notifications .notifications-box label {
      font-size: 16px;
      font-weight: bold;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .notifications .notifications-box label span {
        padding-right: 24px;
        padding-left: 45px; }

.profile-input-wrapper label {
  font-size: 18px;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 14px; }
  .profile-input-wrapper label .label {
    width: 100px; }
  .profile-input-wrapper label .profile-input {
    width: 290px;
    height: 42px;
    border-radius: 5px; }
  .profile-input-wrapper label .nis {
    padding: 0 15px; }

.personal-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }
  .personal-details img {
    width: 235px;
    height: 235px;
    border-radius: 100%; }
  .personal-details .submit-button {
    margin-top: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 100px; }
    .personal-details .submit-button button {
      font-size: 24px;
      line-height: 24px;
      padding: 6px 73px; }
  .personal-details .reset-message {
    color: #f37341;
    border: 1px solid;
    border-radius: 6px;
    padding: 5px 20px;
    margin-top: 40px; }

.price-methods .profile-input-wrapper label .label {
  width: 230px; }

.price-methods label {
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.price-methods .profile-input-wrapper label .profile-input {
  width: 195px; }

.profile-page h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px; }

.profile-page .profile-box {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding-right: 36px;
  padding-top: 42px; }

.profile-page .personal-info h2 {
  margin-top: calc(49px - 27px); }

.profile-page .personal-details-wrapper,
.profile-page .price-methods-wrapper {
  min-height: 370px; }

.profile-page .price-methods-wrapper {
  padding-top: 42px; }

.profile-page .custom-offset-7 {
  margin-right: 58.333333%; }

.profile-page .notifications-wrapper {
  margin-top: 40px; }

.header-info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px; }
  .header-info .right-side {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .header-info .dropdown {
    margin-left: 20px; }
    .header-info .dropdown button {
      border: none;
      background-color: #ffffff;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: baseline;
              align-items: baseline;
      width: 240px; }
      .header-info .dropdown button:hover {
        background-color: #ffffff;
        color: #233e4f; }
      .header-info .dropdown button::after {
        margin-right: 0.255em; }
    .header-info .dropdown .dropdown-menu {
      width: 100%; }
      .header-info .dropdown .dropdown-menu a {
        color: #257aa9; }
  .header-info .file-info {
    font-size: 16px; }
    .header-info .file-info .item {
      padding: 0 10px; }
    .header-info .file-info .value {
      color: #257aa9; }
  .header-info .submit-buttons {
    display: -webkit-flex;
    display: flex;
    border: 0; }
    .header-info .submit-buttons .submit-button {
      margin: 0 14px; }
      .header-info .submit-buttons .submit-button button {
        font-size: 21px; }
      .header-info .submit-buttons .submit-button .secondary {
        color: #257aa9; }

.circle {
  border: 1px solid rgba(139, 140, 146, 0.5);
  cursor: pointer;
  min-width: 19px;
  max-width: 19px;
  min-height: 19px;
  max-height: 19px;
  border-radius: 100%;
  margin: 0 10px; }

.tags-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
  width: 225px;
  position: relative;
  z-index: 9999; }
  .tags-popup form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 2px 2.2px 5px 0 rgba(124, 132, 135, 0.66);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px;
    margin: 0; }
    .tags-popup form label {
      width: 100%;
      color: #7c8487;
      margin-bottom: 2px; }
    .tags-popup form input {
      height: 22px; }
    .tags-popup form .submit-button {
      margin: 8px; }
      .tags-popup form .submit-button button {
        padding: 2px 7px;
        font-size: 14px; }
    .tags-popup form .tag-input-wrapper {
      display: -webkit-flex;
      display: flex;
      margin-bottom: 10px; }
  .tags-popup .recommendation-tag-list {
    list-style: none;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: 120px; }
    .tags-popup .recommendation-tag-list li {
      box-shadow: 2px 2.2px 5px 0 rgba(124, 132, 135, 0.66);
      background-color: #ffffff;
      margin-bottom: 5px;
      border-radius: 4px;
      font-size: 16px;
      color: #233e4f;
      display: inline-block;
      max-width: 100%;
      word-break: break-all;
      padding: 2px 10px; }
      .tags-popup .recommendation-tag-list li .recommended-tags-wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        line-height: normal;
        line-height: initial;
        cursor: pointer; }

.popover {
  font-family: 'Open Sans Hebrew', sans-serif; }

.tags-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
  width: 225px;
  position: relative;
  z-index: 9999; }
  .tags-popup .wrapper {
    min-width: 250px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 2px 2.2px 5px 0 rgba(124, 132, 135, 0.66);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px;
    margin: 0; }
    .tags-popup .wrapper .tag-name {
      display: -webkit-flex;
      display: flex; }
      .tags-popup .wrapper .tag-name h3 {
        font-size: 16px;
        color: #7c8487; }
    .tags-popup .wrapper .buttons {
      margin-top: 15px;
      margin-bottom: 0;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      width: 100%; }
      .tags-popup .wrapper .buttons .submit-button {
        margin-bottom: 0; }
        .tags-popup .wrapper .buttons .submit-button button {
          font-size: 14px;
          line-height: 1; }

.remove-tag-button {
  display: none;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #dcdcdc;
  cursor: pointer;
  margin-right: -5px;
  margin-top: -5px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3); }

.tag-hover:hover + .remove-tag-button,
.remove-tag-button:hover {
  display: inline-block; }

.remove-tag-x {
  position: absolute;
  left: 5px;
  bottom: -5px;
  font-size: 14px;
  font-weight: bold;
  color: red; }

.paragraph-editor .DraftEditor-root {
  position: relative; }
  .paragraph-editor .DraftEditor-root .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: grey;
    font-size: 0.9em;
    opacity: 0.7;
    font-style: italic; }

.paragraph-editor .invalid-chars-alert {
  color: red;
  border: solid 1px red;
  border-radius: 5px;
  padding: 0 1em;
  background-color: rgba(255, 0, 0, 0.2); }

.entry {
  width: 100%;
  padding: 0; }
  .entry .paragraph {
    padding: 5px 20px 5px 35px;
    display: -webkit-flex;
    display: flex; }
    .entry .paragraph p {
      margin: 0; }
    .entry .paragraph + .paragraph {
      border-top: 1px dotted #cccccc; }
    .entry .paragraph .speaker {
      width: 150px;
      font-weight: 600;
      margin-left: 20px;
      padding-left: 10px;
      border-left: 1px dashed #cccccc; }
      .entry .paragraph .speaker .dropdown {
        margin: 0;
        height: auto; }
        .entry .paragraph .speaker .dropdown button {
          min-width: 215px; }

.customer-block {
  background-color: #ffffff; }
  .customer-block .ts,
  .customer-block .entry {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee0e3; }
  .customer-block .ts {
    text-align: center; }

.block {
  background-color: #ffffff;
  border-bottom: 1px solid #dee0e3; }
  .block .time-code {
    min-width: 80px;
    border-left: 4px solid #f6f6f6;
    cursor: pointer; }
    .block .time-code span {
      position: relative;
      color: #233e4f;
      font-size: 11px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
    .block .time-code .modify-transcript {
      position: absolute;
      top: -10px;
      right: -5px;
      color: #f37341;
      font-size: 14px;
      cursor: pointer; }
      .block .time-code .modify-transcript.block-offset-modified {
        color: #0ab116; }

.sync-status-message {
  font-size: 20px;
  font-weight: bold; }
  .sync-status-message.success {
    color: #0ab116; }
    .sync-status-message.success .icon {
      margin-left: 10px; }
  .sync-status-message.error {
    color: #bb0000; }

.text-editor {
  border-top: 1px solid #ececed; }
  .text-editor table {
    display: block;
    width: 100%;
    margin: 0 auto;
    word-wrap: break-word; }
    .text-editor table thead tr:nth-child(2) {
      background-color: #ffffff; }
    .text-editor table thead .transcript {
      height: 50px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .text-editor table thead .transcript .shortcuts-configuration {
        cursor: pointer; }
        .text-editor table thead .transcript .shortcuts-configuration img {
          margin-left: 7px;
          margin-right: 13px; }
    .text-editor table thead .speaker {
      width: 150px;
      min-width: 150px;
      padding-right: 5px; }
    .text-editor table th {
      width: 100%; }
      .text-editor table th .dropdown {
        width: 55px; }
        .text-editor table th .dropdown button {
          width: 40px;
          border: none;
          padding-right: 5px;
          background-color: #ffffff; }
          .text-editor table th .dropdown button:hover {
            color: #233e4f; }
      .text-editor table th:first-child {
        border-left: 4px solid #f6f6f6;
        width: 80px;
        min-width: 80px; }
    .text-editor table tbody {
      background-color: #ffffff;
      display: block; }
      .text-editor table tbody tr:first-child td {
        border-top: 4px solid #f6f6f6; }
    .text-editor table ::-webkit-scrollbar {
      -webkit-appearance: none; }
    .text-editor table ::-webkit-scrollbar:vertical {
      width: 11px; }
    .text-editor table ::-webkit-scrollbar:horizontal {
      height: 11px; }
    .text-editor table ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5); }
  .text-editor .table-header {
    background-color: #f6f6f6;
    font-size: 16px; }
    .text-editor .table-header th {
      font-weight: normal;
      background-color: #f6f6f6; }

.customer-editor table {
  display: block;
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word;
  border-spacing: 0; }
  .customer-editor table thead th {
    border-bottom: 4px solid #f6f6f6; }
  .customer-editor table thead .ts {
    min-width: 80px; }
  .customer-editor table thead .checkbox {
    min-width: 40px;
    background-color: #f6f6f6; }
  .customer-editor table thead .transcript {
    width: 100%;
    text-align: right; }
  .customer-editor table thead .tc {
    border-left: 4px solid #f6f6f6;
    text-align: center;
    padding: 13px 18px;
    line-height: 14px;
    background-color: #ffffff; }
  .customer-editor table thead .speaker {
    width: 150px;
    min-width: 150px;
    padding-right: 5px; }
  .customer-editor table thead .bg-white {
    background-color: #ffffff; }
  .customer-editor table tbody {
    display: block;
    overflow-y: auto;
    overflow-x: hidden; }
    .customer-editor table tbody tr {
      padding-bottom: 10px; }
    .customer-editor table tbody .ts {
      border-left: 4px solid #f6f6f6;
      min-width: 80px;
      font-size: 11px;
      cursor: pointer; }
    .customer-editor table tbody .checkbox {
      vertical-align: middle;
      min-width: 40px;
      background-color: #f6f6f6;
      text-align: center; }
    .customer-editor table tbody .transcript {
      padding-bottom: 10px;
      padding-left: 36px; }
      .customer-editor table tbody .transcript .editor-area .paragraph {
        display: -webkit-flex;
        display: flex; }
      .customer-editor table tbody .transcript .editor-area .speaker {
        min-width: 150px;
        text-decoration: underline;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        margin-left: 20px; }
  .customer-editor table ::-webkit-scrollbar {
    -webkit-appearance: none; }
  .customer-editor table ::-webkit-scrollbar:vertical {
    width: 11px; }
  .customer-editor table ::-webkit-scrollbar:horizontal {
    height: 11px; }
  .customer-editor table ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5); }

.time-code-dropdown {
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline; }

.time-code-dropdown::after {
  margin-right: .255em; }

.editor-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .editor-toolbar button {
    margin: 0 10px;
    border: none; }

.popover {
  font-family: 'Open Sans Hebrew', sans-serif; }

.video-controls {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 0; }
  .video-controls.disabled {
    color: lightgray; }
    .video-controls.disabled svg {
      color: lightgray; }
    .video-controls.disabled .timestamp svg {
      color: #6c757d; }
  .video-controls button {
    border: none; }
    .video-controls button:focus {
      box-shadow: none; }
  .video-controls .btn-group {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .video-controls .timestamp svg {
    fill: #6c757d; }
  .video-controls .timestamp button {
    font-size: 10px; }
  .video-controls .timestamp span {
    color: #f37341;
    font-weight: bold;
    font-size: 22px; }
  .video-controls .playback-rate-selector {
    height: auto;
    margin: auto; }
    .video-controls .playback-rate-selector .dropdown-menu {
      min-width: 0; }
    .video-controls .playback-rate-selector button {
      margin: 0 auto;
      width: auto; }

.player .body-wrapper {
  padding: 0 20px; }
  .player .body-wrapper input[type="range"] {
    width: 100%;
    direction: ltr;
    margin-top: 30px; }

.record-date-picker {
  margin-top: 20px;
  padding-bottom: 20px; }
  .record-date-picker h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px; }
  .record-date-picker .date-label {
    margin-bottom: 4px; }

.customer-record-date {
  padding-top: 15px;
  border-top: 1px solid rgba(35, 62, 79, 0.2);
  margin-bottom: 20px; }
  .customer-record-date h3 {
    font-weight: bold;
    font-size: 14px; }

.speakers {
  margin-top: 10px;
  padding-bottom: 10px; }
  .speakers .speaker-header {
    min-height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .speakers h3 {
    font-weight: bold;
    font-size: 14px; }
  .speakers .configure-button {
    position: inherit; }
  .speakers table {
    width: 100%; }
    .speakers table th {
      font-weight: normal; }
    .speakers table tr {
      border-bottom: 1px solid rgba(35, 62, 79, 0.2);
      padding: 5px 0; }
    .speakers table td {
      padding: 2px 0; }
    .speakers table .speaker-name {
      color: #35c4e8; }
    .speakers table .shortcut {
      background-color: #233e4f;
      color: #ffffff;
      border-radius: 10px;
      font-weight: bold;
      padding: 2px 10px;
      font-size: 12px; }

.video-player {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow: auto;
  background-color: #ffffff; }
  .video-player .player {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    border: none; }
  .video-player .body-wrapper {
    padding: 0 20px; }
    .video-player .body-wrapper .speakers,
    .video-player .body-wrapper .video-time-pairing,
    .video-player .body-wrapper .record-date-picker {
      border-bottom: 1px solid rgba(35, 62, 79, 0.2); }
      .video-player .body-wrapper .speakers:last-child,
      .video-player .body-wrapper .video-time-pairing:last-child,
      .video-player .body-wrapper .record-date-picker:last-child {
        border-bottom: none; }
  .video-player .video-time-pairing {
    margin-top: 10px;
    padding-bottom: 10px; }
    .video-player .video-time-pairing label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .video-player .video-time-pairing label input {
        margin-left: 5px; }

.transcript-bottom-padder {
  padding-bottom: 200px; }

.export-bar {
  height: 46px;
  background-color: #2F4554;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1.5rem; }
  .export-bar > li {
    display: inline-block;
    zoom: 1;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 200ms; }
    .export-bar > li:hover {
      color: #35c4e8; }
  .export-bar img {
    margin-left: 13px; }
  .export-bar .quotes img {
    height: 15px; }
  .export-bar .export img {
    height: 21px; }
  .export-bar .bubble img {
    height: 21px; }
  .export-bar .message img {
    height: 18px; }
  .export-bar .line-break {
    padding-right: 23px;
    padding-left: 23px;
    color: #35c4e8; }

.project-header-info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .project-header-info .category-info {
    margin-right: 7px;
    display: -webkit-flex;
    display: flex; }
    .project-header-info .category-info .info {
      padding-left: 50px; }
      .project-header-info .category-info .info .value {
        color: #257aa9; }
  .project-header-info .submit-button button {
    font-size: 21px;
    padding: 0.375rem 32px; }
  .project-header-info .export-button {
    margin-right: auto;
    margin-left: 10px; }

.project-page table {
  margin-top: 20px; }

.search-result {
  height: 100%; }
  .search-result .files-table thead tr th {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .search-result .files-table tbody .file-row {
    cursor: pointer;
    border-bottom: 1px solid #EAEAEB; }
    .search-result .files-table tbody .file-row .project-name {
      font-weight: bold;
      max-width: 300px; }
  .search-result .files-table tbody .active {
    background-color: #ececed; }
  .search-result table {
    border-left: 1px solid #EAEAEB;
    margin-bottom: 0; }
    .search-result table:first-child {
      border-top: 1px solid #EAEAEB; }
    .search-result table thead {
      font-size: 16px; }
      .search-result table thead tr {
        background-color: #F4F3F5; }
        .search-result table thead tr th {
          padding: 3px 0; }
          .search-result table thead tr th.th-project {
            width: 200px; }
    .search-result table tbody {
      font-size: 14px; }
      .search-result table tbody .paragraph-row td {
        border-top: 1px solid #EAEAEB;
        border-right: none;
        border-bottom: none;
        border-left: 2px solid #EAEAEB; }
      .search-result table tbody .paragraph-row:first-child td {
        border-top: none; }
      .search-result table tbody .paragraph-row:last-child td {
        border-bottom: none; }
    .search-result table .speakers {
      color: #949c9f;
      width: 200px; }
    .search-result table .file-record-date-column {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding-left: 54px; }
    .search-result table .project-column {
      text-align: right;
      width: 300px;
      padding-right: 17px; }
    .search-result table .markdown {
      color: #f37341;
      font-weight: bold; }
    .search-result table .active {
      background-color: #ffffff; }
    .search-result table .blue-and-normal {
      color: #2c98d2;
      font-weight: normal; }
  .search-result .pagination-spinner {
    z-index: 2;
    position: absolute;
    bottom: 10px;
    left: 50%; }
  .search-result .loading-list {
    padding-bottom: 100px; }

.search-results .left-panel-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.player {
  border: 1px solid #dddddd; }

.readonly-transcript .speaker {
  text-decoration: underline; }

.readonly-transcript .table {
  display: block; }

.readonly-transcript tbody {
  display: block;
  overflow-y: auto;
  max-height: 100vh; }
  .readonly-transcript tbody .active {
    background: white; }
  .readonly-transcript tbody tr {
    display: -webkit-flex;
    display: flex; }
    .readonly-transcript tbody tr td {
      width: 100%;
      border: 1px solid rgba(234, 234, 235, 0.7); }
      .readonly-transcript tbody tr td:first-child {
        width: 130px;
        min-width: 130px; }
    .readonly-transcript tbody tr:first-child {
      padding-top: 10px; }
      .readonly-transcript tbody tr:first-child td {
        border-top: none; }

.readonly-transcript li {
  background-color: transparent;
  border-bottom: none; }

.flatpickr-calendar .dayContainer,
.flatpickr-calendar .flatpickr-weekdaycontainer {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }

.flatpickr-calendar .flatpickr-months .flatpickr-month {
  height: 32px; }

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month {
  left: unset;
  right: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  right: unset;
  left: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: 10px 0 0 #569ff7; }

.flatpickr-input {
  margin: 10px;
  border-radius: 4px;
  border: 1px solid #EAEAEB;
  height: 42px;
  width: 200px;
  padding: 0.375rem 0.75rem; }
  .flatpickr-input::-webkit-input-placeholder {
    color: #233e4f; }
  .flatpickr-input::placeholder {
    color: #233e4f; }
  @media (max-width: 1440px) {
    .flatpickr-input {
      width: 150px; } }

.multi-select {
  width: 250px; }
  @media (max-width: 1440px) {
    .multi-select {
      width: 170px; } }
  .multi-select .dropdown-heading {
    background-color: #f3f3f4 !important;
    border: 1px solid #EAEAEB !important;
    height: 42px !important; }
    .multi-select .dropdown-heading .dropdown-heading-value {
      line-height: 40px !important; }
      .multi-select .dropdown-heading .dropdown-heading-value span {
        color: #233e4f !important; }
  .multi-select .dropdown-content {
    width: 400px !important; }
  .disabled .multi-select .dropdown-content,
  .disabled .multi-select .dropdown-heading-dropdown-arrow {
    display: none !important; }
  .multi-select ul {
    padding-right: 0 !important; }
  .multi-select label {
    margin-bottom: 0; }

.search-input {
  margin: 10px;
  position: relative; }
  .search-input .last-searches {
    z-index: 1;
    position: absolute;
    width: 300px; }
    @media (max-width: 1440px) {
      .search-input .last-searches {
        width: 250px; } }
    .search-input .last-searches ul {
      padding: 0; }
      .search-input .last-searches ul li {
        cursor: pointer; }

.search-fields .input-wrapper {
  height: auto; }

.search-fields .dropdowns form {
  padding: 10px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .search-fields .dropdowns form .submit-button {
    margin: 10px; }
    .search-fields .dropdowns form .submit-button button {
      padding: 0.375rem 32px;
      font-size: 22px;
      line-height: 28px; }
  .search-fields .dropdowns form .placeholder-disabled-input {
    margin: 10px;
    background-color: #f3f3f4;
    border: 1px solid #EAEAEB;
    padding: 0.375rem 0.75rem;
    width: 200px;
    height: 42px; }
    @media (max-width: 1440px) {
      .search-fields .dropdowns form .placeholder-disabled-input {
        width: 150px; } }
    .search-fields .dropdowns form .placeholder-disabled-input::-webkit-input-placeholder {
      color: #aaaaaa; }
    .search-fields .dropdowns form .placeholder-disabled-input::placeholder {
      color: #aaaaaa; }
  .search-fields .dropdowns form .search-dropdown button {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 25px; }
  .search-fields .dropdowns form .projects-dropdown button {
    width: 250px; }
    @media (max-width: 1440px) {
      .search-fields .dropdowns form .projects-dropdown button {
        width: 150px; } }
    .search-fields .dropdowns form .projects-dropdown button:hover {
      color: #233e4f; }
  .search-fields .dropdowns form .files-dropdown .multi-select .dropdown-content {
    width: 600px !important; }
  .search-fields .dropdowns form .tags-dropdown .multi-select .dropdown-content {
    width: 300px !important; }

.search-fields .eraser {
  color: #f37341;
  cursor: pointer; }

.search-page .bg {
  width: 100%;
  min-height: 248px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/search-page-no-results-strip.cd4b7d09.png);
  position: relative; }

.search-page .illustration {
  max-width: 1024px;
  position: relative;
  top: -25px; }

.scroller {
  overflow-x: hidden; }

.upload-files-status {
  border: 1px solid cadetblue;
  color: #257aa9;
  position: fixed;
  bottom: 0;
  max-height: 280px;
  width: 520px;
  min-height: 30px;
  background-color: #ffffff;
  left: 32px;
  z-index: 1; }
  .upload-files-status .minimize-wrapper {
    height: 15px; }
  .upload-files-status .expand-wrapper {
    height: 30px;
    font-size: 17px; }
  .upload-files-status .popup-status {
    margin: 8px 8px 0 0;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .upload-files-status .popup-status .minimize {
      width: 13px;
      height: 4px;
      background-color: #b7b7b7; }
    .upload-files-status .popup-status .expand {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .upload-files-status .popup-status .expand .expand-icon {
        width: 20px;
        height: 17px;
        border: 3px solid #b7b7b7;
        background-color: transparent;
        margin-left: 10px; }
  .upload-files-status .wrapper {
    margin: 0 auto;
    width: 88%; }
    .upload-files-status .wrapper h2 {
      font-size: 24px;
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #dddddd; }
    .upload-files-status .wrapper h3 {
      font-size: 14px;
      text-align: center;
      padding: 10px 0;
      font-weight: bold; }
    .upload-files-status .wrapper ul {
      list-style: none;
      border-bottom: 1px solid rgba(221, 221, 221, 0.5);
      margin: 0;
      padding: 0 0 22px; }
      .upload-files-status .wrapper ul li {
        font-size: 14px;
        line-height: 14px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        border: none; }
        .upload-files-status .wrapper ul li .file-name {
          width: 120px; }
        .upload-files-status .wrapper ul li .invisible {
          opacity: 0; }
        .upload-files-status .wrapper ul li .cancel-button {
          background-color: transparent;
          border: none; }
          .upload-files-status .wrapper ul li .cancel-button:disabled img {
            opacity: 0.5;
            width: 18px; }
        .upload-files-status .wrapper ul li .progress {
          width: 250px;
          margin: 0 14px;
          height: 10px;
          direction: ltr; }
  .upload-files-status .cancel-buttons-area {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 12px 0; }
    .upload-files-status .cancel-buttons-area .submit-button button {
      padding: 3px 30px;
      font-size: 21px; }

.submit-for-approval-modal {
  text-align: center; }
  .submit-for-approval-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border: none; }
    .submit-for-approval-modal .modal-header .modal-title {
      font-size: 36px;
      padding: 30px 150px 0 150px;
      font-weight: bold; }
  .submit-for-approval-modal .modal-body {
    font-size: 22px; }
  .submit-for-approval-modal .modal-footer {
    -webkit-justify-content: center;
            justify-content: center;
    border: none;
    margin-bottom: 40px; }
    .submit-for-approval-modal .modal-footer .submit-button button {
      padding: .375rem 45px;
      font-size: 21px; }
    .submit-for-approval-modal .modal-footer .submit-button:nth-child(1) {
      margin-left: 13px; }

.popover {
  font-family: 'Open Sans Hebrew', sans-serif; }

.submit-buttons {
  padding-top: 38px;
  border-top: 1px solid rgba(139, 140, 146, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .submit-buttons .submit-button {
    margin-bottom: 17px; }
    .submit-buttons .submit-button button {
      font-size: 21px;
      padding: 3px 70px; }

.tab .add {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 25px; }
  .tab .add .text {
    padding-right: 20px;
    font-size: 18px;
    line-height: 2; }

.tab .input-wrapper {
  height: auto;
  width: 445px;
  margin-top: 20px; }
  .tab .input-wrapper .input input {
    border: 1px solid #ced4da; }
  .tab .input-wrapper .input label {
    font-size: 18px;
    color: rgba(35, 62, 79, 0.6); }

.tab .table thead tr {
  background-color: transparent;
  border: none; }
  .tab .table thead tr th {
    text-align: right;
    font-size: 18px;
    color: rgba(35, 62, 79, 0.6);
    font-weight: normal; }

.tab .table tbody tr td {
  text-align: right;
  padding: 7px; }
  .tab .table tbody tr td:nth-child(1) {
    width: 20px; }
  .tab .table tbody tr td:nth-child(2) {
    width: 400px; }

.shortcuts-modal .modal-body {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px; }

.shortcuts-modal h2 {
  font-size: 35px; }

.shortcuts-modal .tabs {
  margin-top: 37px;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around; }
  .shortcuts-modal .tabs .tab {
    height: 50px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer; }
  .shortcuts-modal .tabs .active {
    background-color: #dee0e3; }
  .shortcuts-modal .tabs h3 {
    font-size: 20px; }

.add-files-modal .modal-header {
  border-bottom: none;
  padding: 40px 30px; }
  .add-files-modal .modal-header .modal-title {
    font-size: 35px;
    line-height: 35px; }

.add-files-modal .modal-body form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.add-files-modal .modal-body .warning {
  color: #f37341;
  font-size: 14px;
  padding-top: 27px;
  padding-bottom: 15px; }

.add-files-modal ul {
  padding: 0; }
  .add-files-modal ul li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: none;
    padding: 10px 40px;
    font-size: 16px;
    line-height: 16px; }
    .add-files-modal ul li .due-date {
      text-align: right;
      width: 170px; }
    .add-files-modal ul li .file-name {
      width: 450px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .add-files-modal ul li .file-name p {
        margin: 0; }
      .add-files-modal ul li .file-name img {
        padding-left: 10px; }
    .add-files-modal ul li input {
      border: 1px solid #EAEAEB;
      border-radius: 4px;
      padding: 6px 0;
      text-align: center;
      color: #bbc0c2;
      font-size: 15px; }

.add-files-modal .drop-zone-wrapper:focus {
  outline: none; }

.add-files-modal .drop-zone-wrapper .drop-zone {
  cursor: pointer;
  width: 450px;
  height: 130px;
  border-width: 2px;
  border-style: dashed;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-color: #35c4e8;
  background-color: #f6f6f6;
  position: relative; }
  .add-files-modal .drop-zone-wrapper .drop-zone img {
    position: absolute;
    top: 19px; }
  .add-files-modal .drop-zone-wrapper .drop-zone h4 {
    color: #bbc0c2;
    font-size: 19px;
    padding-bottom: 26px;
    font-weight: bold; }

.add-files-modal .submit-buttons {
  width: 450px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 33px; }
  .add-files-modal .submit-buttons button {
    font-size: 21px;
    line-height: 21px;
    padding: 11px 45px; }

.speakers-modal {
  padding: 25px;
  max-height: 90vh; }
  .speakers-modal .modal-header {
    border: none; }
    .speakers-modal .modal-header .modal-title {
      font-size: 35px; }
  .speakers-modal .modal-body {
    overflow: auto; }
    .speakers-modal .modal-body table {
      width: 100%; }
      .speakers-modal .modal-body table thead tr {
        background-color: #f6f6f6;
        border-radius: 3px;
        border: none; }
      .speakers-modal .modal-body table thead th {
        font-size: 20px;
        font-weight: normal; }
        .speakers-modal .modal-body table thead th:last-child {
          width: 185px; }
      .speakers-modal .modal-body table tbody tr {
        border-bottom: 1px solid #f6f6f6; }
        .speakers-modal .modal-body table tbody tr:first-child td {
          padding-top: 15px; }
        .speakers-modal .modal-body table tbody tr td {
          padding: 7px 0; }
      .speakers-modal .modal-body table tbody .delete-speaker {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        cursor: pointer; }
      .speakers-modal .modal-body table tbody input {
        height: 28px;
        width: 100%;
        color: #35c4e8;
        border: 1px solid #ced4da;
        font-size: 1rem;
        padding-right: 10px; }
        .speakers-modal .modal-body table tbody input:focus {
          color: #35c4e8; }
      .speakers-modal .modal-body table tbody .input-wrapper {
        height: 28px; }
      .speakers-modal .modal-body table tbody .speaker-input {
        width: 160px; }
      .speakers-modal .modal-body table tbody .shortcut-input {
        width: 160px; }
    .speakers-modal .modal-body .add-speaker {
      margin-top: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      margin-right: 10px;
      z-index: 1; }
      .speakers-modal .modal-body .add-speaker .text {
        padding-right: 20px;
        font-size: 18px;
        line-height: 2; }

.convert-to-tags-modal .modal-header {
  border: none; }
  .convert-to-tags-modal .modal-header .modal-title {
    font-size: 36px;
    padding: 30px 150px 0 150px;
    font-weight: bold; }

.convert-to-tags-modal .modal-body {
  font-size: 22px; }
  .convert-to-tags-modal .modal-body .picker-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .convert-to-tags-modal .modal-body .picker-wrapper .circle {
      position: relative;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px; }
    .convert-to-tags-modal .modal-body .picker-wrapper .picker {
      position: absolute;
      right: -50px;
      width: 200px; }
    .convert-to-tags-modal .modal-body .picker-wrapper .select-tag-field {
      width: 400px; }

.convert-to-tags-modal .modal-footer {
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  margin-bottom: 40px; }
  .convert-to-tags-modal .modal-footer .submit-button button {
    padding: 0.375rem 45px;
    font-size: 21px; }
  .convert-to-tags-modal .modal-footer .submit-button:nth-child(1) {
    margin-left: 13px; }

.download-link p {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 20px;
  margin-top: 20px; }
  .download-link p a {
    font-weight: bold;
    padding-right: 2px; }

.download-link .submit-button {
  text-align: center;
  margin-bottom: 17px;
  margin-top: 30px; }
  .download-link .submit-button button {
    font-size: 21px;
    padding: 0.35rem 70px; }

.export-format-fields .disabled {
  color: grey; }

.export-file-modal {
  padding: 19px 31px; }
  .export-file-modal .description {
    background-color: #f6f6f6;
    font-size: 20px;
    line-height: 20px;
    padding: 16px 11px 13px 0; }
  .export-file-modal .modal-body {
    padding: 0; }
  .export-file-modal .modal-title {
    font-size: 35px; }
  .export-file-modal .modal-header {
    padding: 0 0 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
  .export-file-modal form {
    font-size: 16px;
    margin-top: 30px; }
    .export-file-modal form .radio-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-bottom: 10px; }
    .export-file-modal form input[type='radio'] {
      margin-left: 25px; }
    .export-file-modal form img {
      margin-left: 10px;
      max-width: 25px; }

.export-tags-modal {
  padding: 19px 31px; }
  .export-tags-modal .description {
    background-color: #f6f6f6;
    font-size: 20px;
    line-height: 20px;
    padding: 16px 11px 13px 0;
    margin-bottom: 30px; }
  .export-tags-modal .modal-body {
    padding: 0; }
  .export-tags-modal .modal-title {
    font-size: 35px; }
  .export-tags-modal .modal-header {
    padding: 0;
    border-bottom: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
  .export-tags-modal form {
    font-size: 16px; }
    .export-tags-modal form .multi-select {
      width: 300px; }
    .export-tags-modal form .radio-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-bottom: 10px; }
    .export-tags-modal form input[type='radio'] {
      margin-left: 25px; }
    .export-tags-modal form img {
      margin-left: 10px;
      max-width: 25px; }
    .export-tags-modal form .export-tags {
      padding-bottom: 50px; }

.modify-offset {
  text-align: center; }
  .modify-offset .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border: none; }
    .modify-offset .modal-header .modal-title {
      font-size: 36px;
      padding: 30px 150px 0 150px;
      font-weight: bold; }
  .modify-offset .modal-body .input-wrapper {
    font-size: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .modify-offset .modal-body .input-wrapper input {
      direction: ltr;
      font-size: 25px;
      height: 100%;
      text-align: center;
      width: 250px; }
  .modify-offset .modal-body .warning {
    color: #f37341;
    font-size: 16px;
    padding-top: 27px; }
  .modify-offset .modal-footer {
    -webkit-justify-content: center;
            justify-content: center;
    border: none;
    margin-bottom: 20px; }
    .modify-offset .modal-footer .submit-button button {
      padding: 0.375rem 45px;
      font-size: 21px; }
    .modify-offset .modal-footer .submit-button:nth-child(1) {
      margin-left: 13px; }

.popover {
  font-family: 'Open Sans Hebrew', sans-serif; }

.notification-popover {
  margin-top: 22px; }

.dropdown {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .dropdown button:hover {
    background-color: #ececed; }
  .dropdown button.disabled:hover {
    background-color: transparent; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #f3f3f4; }

.modal-content {
  border-radius: 1px; }

*:focus {
  outline: none !important; }

button:focus {
  box-shadow: none !important;
  outline: none !important; }

a.active {
  text-decoration: underline; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  direction: rtl;
  font-size: 15px; }

body {
  text-align: right;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  direction: rtl; }

*,
*:before,
*:after {
  box-sizing: inherit; }

*:focus {
  outline: none !important; }

button:focus {
  box-shadow: none !important;
  outline: none !important; }

body {
  padding: 0;
  margin: 0;
  line-height: 2;
  color: #233e4f;
  font-family: 'Open Sans Hebrew', sans-serif;
  background-color: #f6f6f6; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

.cursor-pointer {
  cursor: pointer; }

.dashboard-page {
  min-height: 100vh;
  position: relative; }

.fatal-error {
  height: 100vh; }
  .fatal-error.dev {
    background-image: url(/static/media/fatalerror.5a2e48c7.gif);
    background-position: center center;
    background-size: cover; }
    .fatal-error.dev h1 {
      color: white; }

.Toastify__toast {
  direction: rtl; }

.Toastify__toast--info.orange-toast {
  background: #f37341; }

