.search-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    margin-left: 25px;
  }
  .input-wrapper{
    height: auto;
  }
}
