.search-input {
    margin: 10px;
    position: relative;

    .last-searches {
        z-index: 1;
        position: absolute;
        width: 300px;
        @media (max-width: 1440px) {
            width: 250px;
        }

        ul {
            padding: 0;

            li {
                cursor: pointer;
            }
        }
    }
}
