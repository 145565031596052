.paragraph-editor {
    .DraftEditor-root {
        position: relative;

        .public-DraftEditorPlaceholder-root {
            position: absolute;
            color: grey;
            font-size: 0.9em;
            opacity: 0.7;
            font-style: italic;
        }
    }
    .invalid-chars-alert {
        color: red;
        border: solid 1px red;
        border-radius: 5px;
        padding: 0 1em;
        background-color: rgba($color: red, $alpha: 0.2);
    }
}
