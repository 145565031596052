@import '../../../styles/abstract/palette';

.notification-page {
  ul {
    list-style: none;
    padding: 0 20%;
  }
  li {
    margin: 16px 0;
    a {
      color: $color-dark-blue-grey;
      text-decoration: none;
    }
  }
}
