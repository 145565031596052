@import '../../../styles/abstract/all';

.submit-button {
    background-color: $color-white;
    position: relative;
    display: inline-block;
    margin-bottom: 17px;

    .secondary {
        color: $color-bright-light-blue;
    }

    button {
        font-size: 21px;
        min-width: 193px;
        display: flex;
        justify-content: center;
        color: $color-dusty-orange;
        border: 1px solid rgba(0, 0, 0, 0.11);
        text-align: center;
        box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11);
        padding: 0.375rem 1.5rem;
        &:focus {
            box-shadow: 1.3px 1.5px 0 0 rgba(0, 0, 0, 0.11);
        }
    }

    &.auto-width {
        button {
            min-width: inherit;
        }
    }

    &:hover {
        button {
            background-color: $color-dusty-orange;
            color: $color-white;
        }

        svg {
            color: $color-white;
        }
    }
}
