@import '../../../styles/abstract/palette';
.flatpickr-calendar {
  .dayContainer,
  .flatpickr-weekdaycontainer {
    flex-direction: row-reverse;
  }
  .flatpickr-months {
    .flatpickr-month {
      height: 32px;
    }
    .flatpickr-prev-month {
      left: unset;
      right: 0;
      transform: scaleX(-1);
    }
    .flatpickr-next-month {
      right: unset;
      left: 0;
      transform: scaleX(-1);
    }
  }
}
.flatpickr-day {
  &.selected.startRange,
  &.startRange.startRange,
  &.endRange.startRange {
    border-radius: 0 50px 50px 0;
  }
  &.selected.endRange,
  &.startRange.endRange,
  &.endRange.endRange {
    border-radius: 50px 0 0 50px;
  }
  &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  &.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  &.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: 10px 0 0 #569ff7;
  }
}
.flatpickr-input {
  &::placeholder {
    color: $color-dark-blue-grey;
  }

  margin: 10px;
  border-radius: 4px;
  border: 1px solid $color-iron;
  height: 42px;
  width: 200px;
  padding: 0.375rem 0.75rem;

    @media(max-width: 1440px){
        width: 150px;
    }
}
