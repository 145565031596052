@import '../../../styles/abstract/palette';

.text-editor {
    border-top: 1px solid $color-pale-lilac;
    table {
        display: block;
        width: 100%;
        margin: 0 auto;
        word-wrap: break-word;
        thead {
            tr {
                &:nth-child(2) {
                    background-color: $color-white;
                }
            }
            .transcript {
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .shortcuts-configuration {
                    cursor: pointer;
                    img {
                        margin-left: 7px;
                        margin-right: 13px;
                    }
                }
            }
            .speaker {
                width: 150px;
                min-width: 150px;
                padding-right: 5px;
            }
        }
        th {
            width: 100%;
            .dropdown {
                width: 55px;
                button {
                    width: 40px;
                    border: none;
                    padding-right: 5px;
                    background-color: $color-white;
                    &:hover {
                        color: $color-dark-blue-grey;
                    }
                }
            }
            &:first-child {
                border-left: 4px solid $color-very-light-blue;
                width: 80px;
                min-width: 80px;
            }
        }
        tbody {
            background-color: $color-white;
            display: block;
            tr {
                &:first-child {
                    td {
                        border-top: 4px solid $color-very-light-blue;
                    }
                }
            }
        }
        & ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        & ::-webkit-scrollbar:vertical {
            width: 11px;
        }
        & ::-webkit-scrollbar:horizontal {
            height: 11px;
        }
        & ::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid white;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
    .table-header {
        background-color: $color-very-light-blue;
        font-size: 16px;
        th {
            font-weight: normal;
            background-color: $color-very-light-blue;
        }
    }
}

.customer-editor {
    table {
        display: block;
        width: 100%;
        margin: 0 auto;
        word-wrap: break-word;
        border-spacing: 0;
        thead {
            th {
                border-bottom: 4px solid $color-very-light-blue;
            }
            .ts {
                min-width: 80px;
            }
            .checkbox {
                min-width: 40px;
                background-color: $color-very-light-blue;
            }
            .transcript {
                width: 100%;
                text-align: right;
            }
            .tc {
                border-left: 4px solid $color-very-light-blue;
                text-align: center;
                padding: 13px 18px;
                line-height: 14px;
                background-color: $color-white;
            }
            .speaker {
                width: 150px;
                min-width: 150px;
                padding-right: 5px;
            }
            .bg-white {
                background-color: $color-white;
            }
        }
        tbody {
            display: block;
            overflow-y: auto;
            overflow-x: hidden;

            tr {
                padding-bottom: 10px;
            }
            .ts {
                border-left: 4px solid $color-very-light-blue;
                min-width: 80px;
                font-size: 11px;
                cursor: pointer;
            }
            .checkbox {
                vertical-align: middle;
                min-width: 40px;
                background-color: $color-very-light-blue;
                text-align: center;
            }
            .transcript {
                .editor-area {
                    .paragraph {
                        display: flex;
                    }
                    .speaker {
                        min-width: 150px;
                        text-decoration: underline;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        margin-left: 20px;
                    }
                }
                padding-bottom: 10px;
                padding-left: 36px;
            }
        }
        & ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        & ::-webkit-scrollbar:vertical {
            width: 11px;
        }
        & ::-webkit-scrollbar:horizontal {
            height: 11px;
        }
        & ::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid white;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
