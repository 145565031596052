@import '../../../styles/abstract/palette';

.video-player {
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow: auto;
    background-color: $color-white;
    .player {
        position: sticky;
        top: 0;
        background-color: $color-white;
        border: none;
    }
    .body-wrapper {
        padding: 0 20px;

      .speakers,
        .video-time-pairing,
        .record-date-picker {
            border-bottom: 1px solid rgba($color-dark-blue-grey, 0.2);

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .video-time-pairing {
        margin-top: 10px;
        padding-bottom: 10px;
        label {
            display: flex;
            align-items: center;
            input {
                margin-left: 5px;
            }
        }
    }

}
