@import "../../../../styles/abstract/palette";

.tags-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 225px;
    position: relative;
    z-index: 9999;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 2px 2.2px 5px 0 rgba(124, 132, 135, 0.66);
        border-radius: 4px;
        background-color: $color-white;
        padding: 10px;
        margin: 0;

        label {
            width: 100%;
            color: $color-steel;
            margin-bottom: 2px;
        }

        input {
            height: 22px;
        }

        .submit-button {
            margin: 8px;

            button {
                padding: 2px 7px;
                font-size: 14px;
            }
        }

        .tag-input-wrapper {
            display: flex;
            margin-bottom: 10px;
        }
    }
    .recommendation-tag-list {
        list-style: none;
        padding: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        position: absolute;
        bottom: 120px;

        li {
            box-shadow: 2px 2.2px 5px 0 rgba(124, 132, 135, 0.66);
            background-color: $color-white;
            margin-bottom: 5px;
            border-radius: 4px;
            font-size: 16px;
            color: $color-dark-blue-grey;
            display: inline-block;
            max-width: 100%;
            word-break: break-all;
            padding: 2px 10px;

            .recommended-tags-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: initial;
                cursor: pointer;
            }
        }
    }
}
