@import '../../styles/abstract/palette';

$hever-logo-height: 120px;

.login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        font-size: 20px;
        padding: 50px 50px 20px 50px;
        text-align: center;
    }
    form {
        width: 620px;
        background-color: $color-white;
        box-shadow: 28.8px 32px 98px 0 rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .hever-logo {
            margin-top: calc(-#{$hever-logo-height}/ 3);
            img {
                height: $hever-logo-height;
            }
        }
        .submit-button {
            margin: 20px 0 43px 0;
            button {
                padding: 0.375rem 2rem;
                font-size: 20px;
            }
        }
    }
    .otp-form {
        display: flex;
        .submit-button {
            padding: 0 calc(25px / 2);
            button {
                padding: 0 35px;
                line-height: 2.5rem;
            }
        }
    }
    .logout {
        color: $color-dusty-orange;
        cursor: pointer;
        font-size: 20px;
    }
}
