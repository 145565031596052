@import "../../../../styles/abstract/palette";
.modify-offset {
    .modal-header {
        display: flex;
        justify-content: center;
        border: none;
        .modal-title {
            font-size: 36px;
            padding: 30px 150px 0 150px;
            font-weight: bold;
        }
    }
    .modal-body {
        .input-wrapper{
            font-size: 22px;
            display: flex;
            justify-content: center;

            input {
                direction:ltr;
                font-size: 25px;
                height: 100%;
                text-align: center;
                width: 250px;
            }
        }

        .warning {
            color: $color-dusty-orange;
            font-size: 16px;
            padding-top: 27px;
        }
    }
    .modal-footer {
        justify-content: center;
        border: none;
        margin-bottom: 20px;
        .submit-button {
            button {
                padding: 0.375rem 45px;
                font-size: 21px;
            }
            &:nth-child(1) {
                margin-left: 13px;
            }
        }
    }
    text-align: center;
}
