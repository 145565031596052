@import '../../../styles/abstract/palette';

.profile-input-wrapper {
  label {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .label {
      width: 100px;
    }

    .profile-input {
      width: 290px;
      height: 42px;
      border-radius: 5px;
    }
    .nis {
      padding: 0 15px;
    }
  }
}
