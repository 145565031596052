@import '../../../styles/abstract/palette';

.search-results {
    .left-panel-wrapper {
        position: sticky;
        top: 0;
        //max-height: 100vh;
        //overflow-y: auto;
        //padding-top: 1.5rem;
    }
}

.player {
    border: 1px solid $color-very-light-pink;
}
