.submit-for-approval-modal{
  .modal-header{
    display: flex;
    justify-content: center;
    border: none;
    .modal-title{
      font-size: 36px;
      padding: 30px 150px 0 150px;
      font-weight: bold;
    }
  }
  .modal-body{
    font-size: 22px;
  }
  .modal-footer{
    justify-content: center;
    border: none;
    margin-bottom: 40px;
    .submit-button {
      button{
        padding: .375rem 45px;
        font-size: 21px;
      }
      &:nth-child(1){
        margin-left: 13px;
      }
    }
  }
  text-align: center;
}
