@import '../../../styles/abstract/palette';

.common-table {
    font-size: 14px;
    margin-bottom: 45px;

    thead {
        tr {
            border: 1px solid $color-very-light-pink;
            background-color: $color-athens-gray;
        }

        .file-name-column {
            width: 25%;
        }

        .speakers-column {
            width: 250px;
        }

        .actions-column {
            width: 100px;
        }

        .project-column {
            min-width: 250px;
        }

        .duedate-column {
            min-width: 200px;
        }

        th {
            text-align: center;
        }
    }

    tbody {
        tr {
            background-color: $color-white;

            td {
                border-bottom: 1px solid $color-iron;
                text-align: center;
            }
        }
    }
}

.pagination-table {
    display: flex;
    justify-content: flex-end;

    .pagination-item {
        background-color: #f9f9f9 !important;
        color: $color-dusty-orange;
    }
}
