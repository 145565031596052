@import '../../../../styles/abstract/palette';

.sync-status-message {
    font-size: 20px;
    font-weight: bold;

    &.success {
        color: $color-green;
        .icon {
            margin-left: 10px;
        }
    }

    &.error {
        color: $color-red;
    }
}
