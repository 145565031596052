@import '../../../../styles/abstract/palette';
.readonly-transcript {
    .speaker {
        text-decoration: underline;
    }
    .table {
        display: block;
    }
    tbody {
        display: block;
        //position: sticky;
        overflow-y: auto;
        //overflow-x: hidden;
        max-height: 100vh;

        .active {
            background: white;
        }
        tr {
            display: flex;
            td {
                width: 100%;
                border: 1px solid rgba($color-iron, 0.7);
                &:first-child {
                    width: 130px;
                    min-width: 130px;
                }
            }
            &:first-child {
                td {
                    border-top: none;
                }
                padding-top: 10px;
            }
        }
    }
    li {
        background-color: transparent;
        border-bottom: none;
    }
}
