@import '../../../styles/abstract/palette';

.common-table {
    tbody {
        .under-work {
            background-color: $color-ice-blue;
            color: $color-bright-light-blue;
        }

        .almost-due {
            background-color: $color-very-light-pink-two;
            color: $color-dusty-orange;
        }
        .encoding {
            background-color: $color-pale-grey;
            color: $color-dusty-orange;
        }
    }
}
