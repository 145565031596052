@import '../../../../styles/abstract/palette';

.search-history {
  .dropdowns {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .dropdown {
      margin: 0 16px;
      &:first-child {
        margin: 0 19px 0 10px;
      }
    }
    .submit-button {
      margin-right: calc(24px - 19px);
      button {
        font-size: 22px;
        line-height: 27px;
        padding-right: 37px;
        padding-left: 37px;
      }
    }
  }
}
