@import "../../../../styles/abstract/palette";

$image-width: 35px;
$image-height: 44px;
$image-margin: 25px;
.notification-item {
    background-color: $color-white;
    font-size: 16px;
    border-bottom: 1px solid $color-iron;

    &.unread-bg{
        background-color: #e2f7fc
    }

    .notification-item-wrapper {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-iron;

        img {
            width: $image-width;
            height: $image-height;
            margin: $image-margin;
        }

    }

    .notification-creation-date {
        color: $color-dusty-orange;
        display: flex;
        padding: 10px calc(#{$image-width} + #{$image-margin} * 2) 10px 0;

        .middle-created-date-item {
            padding: 0 50px;
        }
    }
}
