@import '../../styles/abstract/palette';

.notification-popover {
    margin-top: 22px;
}
.dropdown {
    flex-wrap: wrap;
    button {
        &:hover {
            background-color: $color-pale-lilac;
        }
        &.disabled:hover {
            background-color: transparent;
        }
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: $color-pale-grey;
}

.modal-content {
    border-radius: 1px;
}

*:focus {
    outline: none !important;
}

button:focus {
    box-shadow: none !important;
    outline: none !important;
}
