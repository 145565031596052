@import '../../../styles/abstract/palette';

.footer {
    height: 50px;
    background-color: $color-white;
    display: flex;
    align-items: center;
    .border {
        border: 1px solid $color-pinkish-grey;
    }
    .social {
        width: 140px;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        img {
            width: 17px;
            height: 100%;
        }
    }
    .hever-details {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 30px;
        a {
            color: $color-pickled-bluewood;
            text-decoration: none;
        }
    }
    .copyright {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $color-dusty-orange;
    }
}
