@import "../../../../styles/abstract/palette";
$size: 19px;
.circle{
  border: 1px solid rgba($color-steel-two, 0.5);
  cursor: pointer;
  min-width: $size;
  max-width: $size;
  min-height: $size;
  max-height: $size;
  border-radius: 100%;
  margin: 0 10px;
}
