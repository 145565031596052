@import 'abstract/all';
@import 'partials/fonts';
@import 'overrides/bootstrap';
@import 'overrides/reactrouter';

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    direction: rtl;
    font-size: 15px;
}
body {
    text-align: right;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    direction: rtl;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

*:focus {
    outline: none !important;
}

button:focus {
    box-shadow: none !important;
    outline: none !important;
}

body {
    padding: 0;
    margin: 0;
    line-height: 2;
    color: $color-dark-blue-grey;
    font-family: 'Open Sans Hebrew', sans-serif;
    background-color: $color-very-light-blue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

.cursor-pointer {
    cursor: pointer;
}

.dashboard-page {
    min-height: 100vh;
    position: relative;
}

.fatal-error {
    height: 100vh;

    &.dev {
        background-image: url('../assets/images/fatalerror.gif');
        background-position: center center;
        background-size: cover;

        h1 {
            color: white;
        }
    }
}

.Toastify__toast{
    direction: rtl;
}

.Toastify__toast--info.orange-toast {
    background: $color-dusty-orange;
}
