@import "../../../../styles/abstract/palette";

.add-files-modal {
  $drop-zone-width: 450px;
  .modal-header {
    border-bottom: none;
    padding: 40px 30px;
    .modal-title {
      font-size: 35px;
      line-height: 35px;
    }
  }
  .modal-body {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .warning{
      color: $color-dusty-orange;
      font-size: 14px;
      padding-top: 27px;
      padding-bottom: 15px;
    }
  }
  ul {
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: none;
      padding: 10px 40px;
      font-size: 16px;
      line-height: 16px;
      .due-date{
        text-align: right;
        width: 170px;
      }
      .file-name {
        width: 450px;
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }
        img {
          padding-left: 10px;
        }
      }
      input {
        border: 1px solid $color-iron;
        border-radius: 4px;
        padding: 6px 0;
        text-align: center;
        color: $color-cloudy-blue-two;
        font-size: 15px;
      }
    }
  }
  .drop-zone-wrapper {
    &:focus {
      outline: none;
    }
    .drop-zone {
      cursor: pointer;
      width: $drop-zone-width;
      height: 130px;
      border-width: 2px;
      border-style: dashed;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-color: $color-bright-light-blue;
      background-color: $color-very-light-blue;
      position: relative;
      img {
        position: absolute;
        top: 19px;
      }
      h4 {
        color: $color-cloudy-blue-two;
        font-size: 19px;
        padding-bottom: 26px;
        font-weight: bold;
      }
    }
  }

  .submit-buttons {
    width: $drop-zone-width;
    display: flex;
    justify-content: space-between;
    padding-top: 33px;
    button {
      font-size: 21px;
      line-height: 21px;
      padding: 11px 45px;
    }
  }
}
