.convert-to-tags-modal {
  .modal-header {
    border: none;
    .modal-title {
      font-size: 36px;
      padding: 30px 150px 0 150px;
      font-weight: bold;
    }
  }
  .modal-body {
    font-size: 22px;
    .picker-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .circle {
        $size: 30px;
        position: relative;
        min-width: $size;
        max-width: $size;
        min-height: $size;
        max-height: $size;
      }
      .picker {
        position: absolute;
        right: -50px;
        width: 200px;
      }
      .select-tag-field{
        width: 400px;
      }
    }
  }
  .modal-footer {
    justify-content: center;
    border: none;
    margin-bottom: 40px;
    .submit-button {
      button {
        padding: 0.375rem 45px;
        font-size: 21px;
      }
      &:nth-child(1) {
        margin-left: 13px;
      }
    }
  }
}
