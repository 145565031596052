@import '../../../styles/abstract/palette';
.search-result {
    height: 100%;
    .files-table {
        thead {
            tr {
                th {
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                }
            }
        }
        tbody {
            .file-row {
                cursor: pointer;
                border-bottom: 1px solid $color-iron;
                .project-name {
                    font-weight: bold;
                    max-width: 300px;
                }
            }
            .active {
                background-color: $color-pale-lilac;
            }
        }
    }
    table {
        &:first-child {
            border-top: 1px solid $color-iron;
        }
        border-left: 1px solid $color-iron;
        margin-bottom: 0;
        thead {
            font-size: 16px;
            tr {
                background-color: $color-athens-gray;
                th {
                    padding: 3px 0;
                    &.th-project {
                        width: 200px;
                    }
                }
            }
        }

        tbody {
            font-size: 14px;
            .paragraph-row {
                td {
                    border-top: 1px solid $color-iron;
                    border-right: none;
                    border-bottom: none;
                    border-left: 2px solid $color-iron;
                }
                &:first-child {
                    td {
                        border-top: none;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
        .speakers {
            color: $color-cool-grey;
            width: 200px;
        }
        .file-record-date-column {
            display: flex;
            justify-content: space-between;
            padding-left: 54px;
        }
        .project-column {
            text-align: right;
            width: 300px;
            padding-right: 17px;
        }
        .markdown {
            color: $color-dusty-orange;
            font-weight: bold;
        }
        .active {
            background-color: $color-white;
        }
        .blue-and-normal {
            color: $color-bluish;
            font-weight: normal;
        }
    }

    .pagination-spinner {
        z-index: 2;
        position: absolute;
        bottom: 10px;
        left: 50%;
    }

    .loading-list {
        padding-bottom: 100px;
    }
}
