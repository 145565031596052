.remove-tag-button {
    display: none;
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #dcdcdc;
    cursor: pointer;
    margin-right: -5px;
    margin-top: -5px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
}

.tag-hover:hover + .remove-tag-button,
.remove-tag-button:hover {
    display: inline-block;
}

.remove-tag-x {
    position: absolute;
    left: 5px;
    bottom: -5px;
    font-size: 14px;
    font-weight: bold;
    color: red;
}
