@import '../../../../styles/abstract/all';

.video-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    &.disabled {
        color: lightgray;
        svg {
            color: lightgray;
        }

        .timestamp {
            svg {
                color: #6c757d;
            }
        }
    }

    button {
        border: none;

        &:focus {
            box-shadow: none;
        }
    }

    .btn-group {
        flex-direction: row-reverse;
    }

    .timestamp {
        svg {
            fill: #6c757d;
        }
        button {
            font-size: 10px;
        }

        span {
            color: $color-dusty-orange;
            font-weight: bold;
            font-size: 22px;
        }
    }

    .playback-rate-selector {
        height: auto;
        margin: auto;
        .dropdown-menu {
            min-width: 0;
        }
        button {
            margin: 0 auto;
            width: auto;
        }
    }
}
