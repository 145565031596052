@import "../../../styles/abstract/palette";

.player {
    .body-wrapper {
      input[type="range"] {
        width: 100%;
        direction: ltr;
        margin-top: 30px;
      }
      padding: 0 20px;
    }
}
