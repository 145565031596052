@import '../../../styles/abstract/palette';
.customer-block {
    background-color: $color-white;
    .ts,
    .entry {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-light-periwinkle;
    }
    .ts {
        text-align: center;
    }
}
