@import '../../../../styles/abstract/palette';

.export-tags-modal {
  padding: 19px 31px;
  .description {
    background-color: $color-very-light-blue;
    font-size: 20px;
    line-height: 20px;
    padding: 16px 11px 13px 0;
    margin-bottom: 30px;
  }
  .modal-body {
    padding: 0;
  }
  .modal-title {
    font-size: 35px;
  }
  .modal-header {
    padding: 0;
    border-bottom: none;
    display: flex;
    justify-content: center;
  }
  form {
    font-size: 16px;
    .multi-select{
      width: 300px;
    }

    .radio-group {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
    }
    input[type='radio'] {
      margin-left: 25px;
    }
    img {
      margin-left: 10px;
      max-width: 25px;
    }
    .export-tags{
      padding-bottom: 50px;
    }
  }
}
