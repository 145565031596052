@import '../../../../styles/abstract/palette';

.record-date-picker {
  margin-top: 20px;
  padding-bottom: 20px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .date-label {
    margin-bottom: 4px;
  }
}

.customer-record-date {
  padding-top: 15px;
  border-top: 1px solid rgba($color-dark-blue-grey, 0.2);
  margin-bottom: 20px;
  h3 {
    font-weight: bold;
    font-size: 14px;
  }
}
