@import '../../../styles/abstract/palette';

.multi-select {
    width: 250px;

    @media (max-width: 1440px) {
        width: 170px;
    }

    .dropdown-heading {
        background-color: $color-pale-grey !important;
        border: 1px solid $color-iron !important;
        height: 42px !important;

        .dropdown-heading-value {
            span {
                color: $color-dark-blue-grey !important;
            }

            line-height: 40px !important;
        }
    }

    .dropdown-content {
        width: 400px !important;
    }

    .disabled & {
        .dropdown-content,
        .dropdown-heading-dropdown-arrow {
            display: none !important;
        }
    }

    ul {
        padding-right: 0 !important;
    }

    label {
        margin-bottom: 0;
    }
}
