@import '../../../styles/abstract/palette';

.dropdown {
  height: 42px;
  margin: 10px;
  .dropdown-item {
    background-color: $color-white;
    border: none;
    border-bottom: 1px solid rgba($color-iron, 0.7);
    &:last-child {
      border-bottom: none;
    }
  }
  button {
    background-color: $color-pale-grey;
    color: $color-dark-blue-grey;
    width: 215px;
    border: 1px solid $color-iron;
    text-align: right;
    height: 42px;
    &::after {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
}
