@import "../../../styles/abstract/palette";
.title {
  background-color: $color-white;
  border: 1px solid $color-very-light-pink;
  margin: 1.5rem 0;
  h1 {
    font-size: 36px;
    color: $color-steel;
    padding: 10px 20px;
  }
}
