@import '../../../../styles/abstract/all';

.tags-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 225px;
    position: relative;
    z-index: 9999;
    .wrapper {
        min-width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 2px 2.2px 5px 0 rgba(124, 132, 135, 0.66);
        border-radius: 4px;
        background-color: $color-white;
        padding: 10px;
        margin: 0;

        .tag-name {
            display: flex;

            h3 {
                font-size: 16px;
                color: $color-steel;
            }
        }

        .buttons {
            margin-top: 15px;
            margin-bottom: 0;
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            .submit-button {
                margin-bottom: 0;
                button {
                    font-size: 14px;
                    line-height: 1;
                }
            }
        }
    }
}
