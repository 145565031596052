@import "../../../styles/abstract/palette";

.shortcuts-modal{
  .modal-body{
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  h2 {
    font-size: 35px;
  }
  .tabs{
    margin-top: 37px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    .tab {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active {
      background-color: $color-light-periwinkle;
    }
    h3{
      font-size: 20px;
    }
  }
}
