@import "../../../../styles/abstract/palette";

.personal-details{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    width: 235px;
    height: 235px;
    border-radius: 100%;
  }
  .submit-button{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    margin-right: 100px;
    button {
      font-size: 24px;
      line-height: 24px;
      padding: 6px 73px;
    }
  }
  .reset-message{
    color: $color-dusty-orange;
    border: 1px solid;
    border-radius: 6px;
    padding: 5px 20px;
    margin-top: 40px;
  }
}
