@import '../../../../styles/abstract/palette';

.export-file-modal {
    padding: 19px 31px;
    .description {
        background-color: $color-very-light-blue;
        font-size: 20px;
        line-height: 20px;
        padding: 16px 11px 13px 0;
    }
    .modal-body {
        padding: 0;
    }
    .modal-title {
        font-size: 35px;
    }
    .modal-header {
        padding: 0 0 25px;
        display: flex;
        justify-content: center;
    }
    form {
        font-size: 16px;

        margin-top: 30px;
        .radio-group {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
        }
        input[type='radio'] {
            margin-left: 25px;
        }
        img {
            margin-left: 10px;
            max-width: 25px;
        }
    }
}
