@import '../../../styles/abstract/palette';
.history-page {
  .total {
    text-align: left;
    color: $color-cool-blue;
    font-size: 20px;
    font-weight: bold;
  }
  .table-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
  }
  h2 {
    font-size: 21px;
    font-weight: bold;
  }
  table {
    thead {
      tr {
        th {
          border-bottom: none;
          text-align: center;
          &:first-child {
            width: 40%;
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
        td {
          text-align: center;
          &:first-child{
            text-align: right;
          }
        }
      }
    }
  }
}
