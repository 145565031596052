@import '../../../styles/abstract/palette';

.scroller {
    overflow-x: hidden;
}

.upload-files-status {
    border: 1px solid cadetblue;
    color: $color-mid-blue;
    position: fixed;
    bottom: 0;
    max-height: 280px;
    width: 520px;
    min-height: 30px;
    background-color: $color-white;
    left: 32px;
    z-index: 1;
    .minimize-wrapper {
        height: 15px;
    }
    .expand-wrapper {
        height: 30px;
        font-size: 17px;
    }
    .popup-status {
        margin: 8px 8px 0 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        .minimize {
            width: 13px;
            height: 4px;
            background-color: $color-very-light-pink-three;
        }
        .expand {
            display: flex;
            justify-content: center;
            align-items: center;
            .expand-icon {
                width: 20px;
                height: 17px;
                border: 3px solid $color-very-light-pink-three;
                background-color: transparent;
                margin-left: 10px;
            }
        }
    }
    .wrapper {
        margin: 0 auto;
        width: 88%;

        h2 {
            font-size: 24px;
            text-align: center;
            padding-bottom: 15px;
            border-bottom: 1px solid $color-very-light-pink;
        }
        h3 {
            font-size: 14px;
            text-align: center;
            padding: 10px 0;
            font-weight: bold;
        }
        ul {
            list-style: none;
            border-bottom: 1px solid rgba($color-very-light-pink, 0.5);
            margin: 0;
            padding: 0 0 22px;

            li {
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                .file-name {
                    width: 120px;
                }
              .invisible {
                opacity: 0;
              }
                .cancel-button {
                    background-color: transparent;
                    border: none;
                    &:disabled {
                        img {
                            opacity: 0.5;
                            width: 18px;
                        }
                    }
                }
                .progress {
                    width: 250px;
                    margin: 0 14px;
                    height: 10px;
                    direction: ltr;
                }
            }
        }
    }
    .cancel-buttons-area {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 12px 0;
        .submit-button {
            button {
                padding: 3px 30px;
                font-size: 21px;
            }
        }
    }
}
