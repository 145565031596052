@import '../../../../styles/abstract/palette';

.search-fields {
    .input-wrapper {
        height: auto;
    }

    .dropdowns {
        form {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .submit-button {
                margin: 10px;

                button {
                    padding: 0.375rem 32px;
                    font-size: 22px;
                    line-height: 28px;
                }
            }

            .placeholder-disabled-input {
                margin: 10px;
                background-color: $color-pale-grey;
                border: 1px solid $color-iron;
                padding: 0.375rem 0.75rem;
                width: 200px;
                height: 42px;

                @media (max-width: 1440px) {
                    width: 150px;
                }
                &::placeholder {
                    color: $color-light-grey;
                }
            }

            .search-dropdown {
                button {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 25px;
                }
            }

            .projects-dropdown {
                button {
                    width: 250px;

                    @media (max-width: 1440px) {
                        width: 150px;
                    }
                    &:hover {
                        color: $color-dark-blue-grey;
                    }
                }
            }

            .files-dropdown {
                .multi-select {
                    .dropdown-content {
                        width: 600px !important;
                    }
                }
            }

            .tags-dropdown {
                .multi-select .dropdown-content {
                    width: 300px !important;
                }
            }
        }
    }

    .eraser {
        color: $color-dusty-orange;
        cursor: pointer;
    }
}
