@import "../../../../styles/abstract/palette";

.block{
  background-color: $color-white;
  border-bottom: 1px solid $color-light-periwinkle;
  .time-code{
    min-width: 80px;
    border-left: 4px solid $color-very-light-blue;
    cursor: pointer;

    span {
      position: relative;
      color: $color-dark-blue-grey;
      font-size: 11px;
      display: flex;
      justify-content: center;
    }
    .modify-transcript{
      position: absolute;
      top: -10px;
      right: -5px;
      color: $color-dusty-orange;
      font-size: 14px;
      cursor: pointer;
        &.block-offset-modified{
            color:$color-green;
        }
    }
  }

}
