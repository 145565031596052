@import "../../../styles/abstract/palette";

.main-page {
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .action-button{
    cursor: pointer;
    text-align: center;
    width: 100px;
  }
}
