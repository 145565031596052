.price-methods {
  .profile-input-wrapper label .label {
    width: 230px;
  }
  label {
    justify-content: space-between;
  }
  .profile-input-wrapper label .profile-input {
    width: 195px;
  }
}
