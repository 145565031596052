@import "../../../styles/abstract/all";

.submit-buttons {
  padding-top: 38px;
  border-top: 1px solid rgba($color-steel-two, 0.3);
  display: flex;
  justify-content: center;
  .submit-button {
    margin-bottom: 17px;
    button {
      font-size: 21px;
      padding: 3px 70px;
    }
  }
}